<template>
  <div>
    <b-table
      :fields="fields"
      :items="rubricPresetUnit"
      small
      caption-top
      bordered
      show-empty
      empty-text="No hay niveles de logro para mostrar."
    >
      <template #cell(description)="row">
        <div class="rich-text-content" v-html="row.item.description"></div>
      </template>
    </b-table>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "RubricPresetPreview",
  components: {},
  props: {
    rubric_preset_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      rubric_presets: names.RUBRIC_PRESETS,
      rubric_preset_units: names.RUBRIC_PRESET_UNITS,
    }),
    rubricPreset() {
      return this.rubric_presets.find((x) => x.id == this.rubric_preset_id);
    },
    rubricPresetUnit() {
      return this.rubric_preset_units
        .filter((x) => x.rubric_preset == this.rubric_preset_id)
        .sort(function (a, b) {
          return a.score > b.score ? -1 : 1;
        });
    },
    fields() {
      return [
        {
          key: "title",
          label: "CATEGORÍA",
          thStyle: {
            background: "var(--kl-menu-color) !important",
            color: "white",
          },
          tdClass: "text-left",
        },
        {
          key: "score",
          label:
            this.rubricPreset && this.rubricPreset.show_percentage_sign
              ? "% LOGRO"
              : "PUNTAJE",
          tdClass: "text-center",
          thStyle: {
            background: "var(--kl-menu-color) !important",
            color: "white",
            width: "100px",
          },
          formatter: (value) => {
            if (this.rubricPreset && this.rubricPreset.show_percentage_sign)
              return value + "%";
            else return value;
          },
        },
        {
          key: "description",
          label: "DESCRIPCIÓN NIVELES DE LOGRO",
          thStyle: {
            background: "var(--kl-menu-color) !important",
            color: "white",
          },
          tdClass: "text-left",
        },
      ];
    },
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
</style>